
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import Image from 'next/image';
import commonTabClasses from '../../styles/index.module.scss';
import classes from './index.module.scss';
import ConfigsContext from '@/contexts/configs';
import Button from '@/components/common/Button';
import { H2, P } from '@/components/common/Typography';
const IntegrationsTab: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { t } = useTranslation('home');
    const { homeIntegrations } = useContext(ConfigsContext);
    return (<div className={clsx(commonTabClasses.layout, classes.layout)}>
      <div className={commonTabClasses.leftSide}>
        <H2 className={commonTabClasses.h2}>{t('Integrations')}</H2>
        <P className={commonTabClasses.paragraph}>
          {t('Connect any system to SMSAPI to automate notifications, customer service and marketing. Benefit from hundreds of pre-built integrations with e-commerce, marketing automation and CRM programmes or create your own connection.')}
        </P>
        <Button className={commonTabClasses.button} href="/integrations" label={t('how-does-smsapi-work-integrations-more-button')} buttonType="primary" icon={{ iconName: 'arrow', placeAfterLabel: true }}/>
      </div>
      <div className={commonTabClasses.rightSide}>
        <div className={classes.integrationLogosContainer}>
          {homeIntegrations.map(({ logo, name }, index) => (<div className={classes.logoWrapper} key={index}>
              <Image alt={`${t('Integrations')} ${name} - SMSAPI`} src={logo} fill/>
            </div>))}
        </div>
      </div>
    </div>);
};
export default IntegrationsTab;

    async function __Next_Translate__getStaticProps__1910ce3e310__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/HowDoesSmsapiWork/components/Tabs/components/IntegrationsTab/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce3e310__ as getStaticProps }
  